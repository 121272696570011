import classNames from 'classnames';
import React from 'react';

import BlockAnimation from '../components/animations/block-animation/block-animation';
import { DELAY_OFFSET } from '../components/animations/config';
import ArticleTeaser from '../components/article-teaser';
import Link from '../components/link';
import { getLink } from '../urls';
import Slider from 'react-slick';

const SectionArticleTeaser = (props) => {
  const {
    link,
    heading,
    label,
    reading,
    buttonText,
    buttonSlug,
    tall,
    darkMode,
    withSubline,
    whiteBg,
    noLabel,
    type,
  } = props;

  const darkTheme = darkMode || false;
  const relatedClasses = classNames({
    'section': true,
    'section-article-teaser': true,
    'section-article-teaser--dark': darkTheme,
    'section-article-teaser--white': whiteBg,
  });

  const sliderSettings = {
    className: 'section-article-teaser__slider',
    accessibility: true,
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToScroll: 1,
    swipeToSlide: true,
    centerMode: true,
    centerPadding: '10%',
    slidesToShow: 1,
  };

  return (
    <div className={relatedClasses}>
      <div className="section-article-teaser__container">
        <BlockAnimation className="section-article-teaser__header">
          {/* If no label text, we still want the label red line */}
          <p className="label">{label || ''}</p>
          {heading && <p className="section-article-teaser__heading">{heading}</p>}
        </BlockAnimation>

        <Slider {...sliderSettings}>
          {reading.map((reading, i) => (
            <ArticleTeaser
              reading={reading}
              tall={tall}
              withSubline={withSubline}
              noLabel={noLabel}
              key={i}
              type={type}
            />
          ))}
        </Slider>

        <section className="article-teaser__list-container">
          {reading.map((reading, i) => (
            <ArticleTeaser
              reading={reading}
              tall={tall}
              withSubline={withSubline}
              noLabel={noLabel}
              key={i}
              index={i}
              type={type}
            />
          ))}
        </section>

        {buttonText && buttonSlug && (
          <BlockAnimation delay={DELAY_OFFSET * 4} className="section-article-teaser__cta">
            <Link className="button-icon button-icon--right" link={link || getLink(props)}>
              {buttonText}
            </Link>
          </BlockAnimation>
        )}
      </div>
    </div>
  );
};

export default SectionArticleTeaser;
